import React, { useState, createRef, useEffect } from "react";
import Left_menu from './Left_menu'
import Header from './Header'
import axios from "axios";
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from 'query-string';
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from "./UseBulkShipmentTracker";

const Create_ticket = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
    const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const[shipment,shipmentState]=React.useState({shipment_detail:[]})
    const [state, setState] = React.useState({  subject:"" , ticket_createdById:"" , details:"",shipment_id:""  , category_id:"" , subcategory_id:""  })
    const [urlorder , seturlorder ] = React.useState({shipment_id:""})
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    console.log("selectedSubcategories == " , selectedSubcategories)
      const [selectedCategory, setSelectedCategory] = useState({category_id:""});
      const[category,setcategory]=React.useState([])
      const [filteredShipments, setFilteredShipments] = useState([]);
      const [selectedShipments, setSelectedShipments] = useState([]);
      const [query, setQuery] = useState('');


      useEffect(()=>{
        const order_number = queryParams.get('order_number');
        if(order_number){
          setState({...state, shipment_id:order_number})
          seturlorder({...urlorder , shipment_id:order_number})
          setSelectedShipments([order_number])
        }
      },[location])

    const axios_get_api = (current_shipment_status) => {

        let full_api = config.apiUrl + `/home/support_shipments`;
        let sendData = {
          dstatus: 1,
          };
    
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          // console.log("-----res-----  ",res);
        
          shipmentState({ ...shipment, shipment_detail:res.data.output })
          }).catch((e) => {
    
          })
      }
      React.useEffect(() => {
        axios_get_api()
        ticketcategory()
      }, [])


     
      const handleSupport = () => {
        let state_detailes = { subject:state.subject , details:state.details,shipment_id:selectedShipments , category_id:state.category_id , subcategory_id:state.subcategory_id }
        if(state.subject!="" && state.details!=""){
        axios.post(config.apiUrl + `/home/add_support`,  state_detailes , { headers: config.headers }).then((result)=>{
                  if(result && result.data != undefined){
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                      })
                      Toast.fire({
                        background:"#3659cd",
                        type: 'success',
                        title: result.data.message,
                        color:"white"
                      });
                        navigate("/customer/tickets")
                    }
         }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: 'Something went wrong',
                    color:"white"
                  });
        });}
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: 'Please Fill the Mandatory Fields ',
                    color:"white"
                  });
            }
    }
    
    const handleChange = (event) => {
      // console.log(event);  
      setState({
          ...state,
          [event.target.name]: event.target.value,
        });
      
    }
    
    const handleChange1 =(event)=>{
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('order_number'); // Remove the 'customer_id' parameter
      currentUrl.searchParams.set('order_number', event.target.value); // Set the new 'customer_id' parameter
      
      // Update the URL
      window.history.replaceState({}, '', currentUrl.toString());
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });

    }
    const ticketcategory = () => {

      let full_api = config.apiUrl + `/home/category_ticket`;
      let sendData = {
        dstatus: 1,
        };
  
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setcategory(res.data.output)
      }).catch((e) => {
    })
    }
    const handleCategoryChange = (e) => {
      const selectedCategoryId = e.target.value;
      setState({...state , category_id:selectedCategoryId})
     const categoryData = category.find((item) => item.category_id === selectedCategoryId);
     if (categoryData) {
        const subcategories = categoryData;
        setSelectedSubcategories(subcategories);
        setSelectedCategory({category_id:e.target.value});
      } else {
        setSelectedSubcategories([]);
        setSelectedCategory({category_id:""});
      }
    };

    const handleSelectsubcategory =(e)=>{
    setState({...state , subcategory_id:e.target.value})
    }

    const handleShipmentInputChange = (e) => {
      const input = e.target.value;
      setQuery(input);
  
      if (input.length >= 4) {
          const matches = shipment.shipment_detail.filter((item) => {
              const orderNumber = item.order_number || ""; // Fallback to empty string if undefined
              const shipmentId = item.shipment_id || "";   // Fallback to empty string if undefined
              const trackingNumber = item.carrier_tracking_number || ""; // Fallback to empty string if undefined
  
              return (
                  orderNumber.toLowerCase().includes(input.toLowerCase()) ||
                  shipmentId.toLowerCase().includes(input.toLowerCase()) ||
                  trackingNumber.toLowerCase().includes(input.toLowerCase())
              );
          });
          setFilteredShipments(matches);
      } else {
          setFilteredShipments([]);
      }
  };
  const handleSuggestionClick = (orderNumber) => {
      if (!selectedShipments.includes(orderNumber)) {
          setSelectedShipments([...selectedShipments, orderNumber]);
      }
      setQuery('');
      setFilteredShipments([]);
  };

  const removeShipment = (orderNumber) => {
      setSelectedShipments(selectedShipments.filter((shipment) => shipment !== orderNumber));
  };

  return (
    <div>
<Left_menu value={12} />
<section className="home">
<Header/>
<section class="bg-light p-5">
  <div class="container ">

    <section class="bg-white p-5 mb-2">
      <div className='row'>
        <div className='col-6'>
        <div class="text-muted">
         <p><a href="/customer/tickets" className="text-decoration-none"><i class="fa fa-angle-left" aria-hidden="true" ></i> Back</a></p>
        
      </div>
        </div>
        <div className='col-6'>
        <p className='text-end'><a href="/customer/tickets" className="text-decoration-none"> View All Tickets</a></p>
        </div>

      </div>
     
      <div class="row pt-1">
        <div class="col-md-12">
       
            <section class="">
              <div className="row">
            <div class="col-12 col-md-6 mb-3">
                <label for="email" class="form-label">Category:</label>
                <select class="form-select" onChange={(e)=>handleCategoryChange(e)}>
                <option>Select</option>
                {category.map((item) => (
              <option key={item.category_id} value={item.category_id}>
                {item.category_name}
              </option>
            ))}
                
                </select>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label for="email" class="form-label">Sub Category:</label>
                <select className="form-select" onChange={(e)=>handleSelectsubcategory(e)}>
            <option>Select</option>
            {selectedSubcategories.subcategory
              ? selectedSubcategories.subcategory.map((sub, index) => (
                  <option key={index} value={sub.sub_id}>
                    {sub.name}
                  </option>
                ))
              : <option>No Subcategories Available</option>}
          </select>
              </div>
              </div>
              <div class="mb-3 mt-3">
                <label for="email" class="form-label">Subject<small className='text-danger'>*</small></label>
                <input type="text" class="form-control" id="email" placeholder="Please enter the subject" name="subject" value={state.subject} onChange={(e) => handleChange(e)}/>
              </div>
              <div class="col-12 col-md-12 mb-3">
               
                <div class="mb-3">
                  <input type="text" class="form-control" id="text" placeholder="Enter shipment number" onChange={handleShipmentInputChange}/>
                </div>
                {filteredShipments.length > 0 && (
                <ul className="list-group mb-3">
                    {filteredShipments.map((item) => (
                        <li
                            key={item.shipment_id}
                            onClick={() => handleSuggestionClick(item.order_number)}
                            className="list-group-item d-flex justify-content-between align-items-center"
                            style={{ cursor: 'pointer' }}
                        >
                            <span>{item.order_number}</span>
                            <span>{item.shipment_id}</span>
                            <span>{item.carrier_tracking_number}</span>
                        </li>
                    ))}
                </ul>
            )}
                <div className="mb-3">
                            <label htmlFor="shipmentTextarea" className="form-label">Selected Shipments:</label>
                            <textarea
                                className="form-control"
                                id="shipmentTextarea"
                                rows="5"
                                readOnly
                                value={selectedShipments.join("\n")}
                            ></textarea>
                            {selectedShipments.map((shipment, index) => (
                                <span key={index} className="badge bg-secondary me-2">
                                    {shipment}
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white ms-2"
                                        onClick={() => removeShipment(shipment)}
                                    ></button>
                                </span>
                            ))}
                        </div>
              </div>                  
              <div class="mb-3 mt-3">
                <label for="comment">Details<small className='text-danger'>*</small></label>
                <textarea class="form-control" rows="5" id="comment" name="details" placeholder="Please enter details" value={state.details} onChange={(e) => handleChange(e)}></textarea>
              </div>

              
              <a  class="btn btn-primary mt-3" onClick={()=>handleSupport()}>Submit</a>
            </section>
     </div>
        
      </div>
    </section>

    
  </div>
</section>
</section>
<Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />
    </div>
    
  )
}

export default Create_ticket
